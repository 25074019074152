import createOperation from '../createOperation';
import * as accountService from './service';

export const createUser = createOperation({
  process: async ({ dispatch, payload: { data } }) => {
    try {
      let params = {
        ...data,
      };
      await accountService.createUser(params);
    } catch (e) {
      console.log(e);
    }
  },
});
