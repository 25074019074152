import { createSlice } from 'redux-starter-kit';

const initialState = {
  loading: false,
  token: null,
};

export default createSlice({
  slice: 'app',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },

    setToken: (state, action) => ({
      ...state,
      token: action.payload,
    }),
  },
});
