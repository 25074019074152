import { connect } from 'react-redux';

import { actions, selectors } from 'store/app';
import App from './index';

const mapStateToProps = state => ({
  // token: selectors.tokenSelector(state),
});
const mapDispatchToProps = {
  // getAccount: actions.getAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
