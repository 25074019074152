export const KEY_LOGIN_USER = "key_login_user"
export const KEY_LOGIN_PASSWORD = "key_login_password"
export const KEY_SUB_DOMAIN = "key_sub_domain"

export const LIST_DATA_FAKE = [
    {
        _id: '53525',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: false,
    },
    {
        _id: '535fe25',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: true,
    },
    {
        _id: '1',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: false,
    },
    {
        _id: '2',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: true,
    },
    {
        _id: '3',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: false,
    },
    {
        _id: '4',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: true,
    },
    {
        _id: '5',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: false,
    },
    {
        _id: '6',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: true,
    },
    {
        _id: '7',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: false,
    },
    {
        _id: '8',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: true,
    },
    {
        _id: '9',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: false,
    },
    {
        _id: '10',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: true,
    },
    {
        _id: '11',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: false,
    },
    {
        _id: '12',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: true,
    },
    {
        _id: '13',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: false,
    },
    {
        _id: '14',
        name: 'Trần Nhật Hoàng',
        avatar:
            'https://scontent.fhan3-3.fna.fbcdn.net/v/t1.30497-1/c141.0.480.480a/p480x480/84688533_170842440872810_7559275468982059008_n.jpg?_nc_cat=1&ccb=2&_nc_sid=7206a8&_nc_ohc=Wfh9YcOfTKwAX_NpOM4&_nc_ht=scontent.fhan3-3.fna&tp=27&oh=ad147cc8fefc05986d69cb54183baea5&oe=600F2D58',
        info: 'hoangtn@gmail.com',
        position: 'Nhân viên kinh doanh',
        locked: true,
    },
];

export const LIST_FIELD_INPUT_GENERAL_FORM_ADDNEW = [
    {
        key: 'fullname',
        label: 'Họ và tên',
        required: true,
        requiredMesage: "Vui lòng nhập họ tên",
    },
    {
        key: 'userName',
        label: 'Username',
        required: true,
        requiredMesage: "Vui lòng UserName",
    },
    /*  {
        key: 'password',
        label: 'Mật khẩu',
        required: true,
        type: 'password',
        requiredMesage: "Vui lòng nhập mật khẩu",
      },
      {
        key: 'confirmpassword',
        label: 'Nhắc lại mật khẩu',
        required: true,
        type: 'password',
        requiredMesage: "Vui lòng nhập xác nhận mật khẩu",
      },*/
    {
        key: 'phoneNumber',
        label: 'Số điện thoại',
        required: true,
        requiredMesage: "Vui lòng nhập số điện thoại",
        type: 'tel',
        typeMessage: 'Vui lòng nhập đúng định dạng số điện thoại'
    },
    {
        key: 'email',
        label: 'Email',
        required: true,
        requiredMesage: "Vui lòng nhập email",
        type: 'email',
        typeMessage: 'Email không đúng định dạng'
    },
    {
        key: 'address',
        label: 'Địa chỉ',
        required: true,
        requiredMesage: "Vui lòng nhập địa chỉ",
    },
    {
        key: 'sex',
        label: 'Giới tính',
        required: true,
        requiredMesage: "Vui lòng chọn giới tính",
        inputTypeGender: true,
    },
    {
        key: 'birthday',
        label: 'Ngày sinh',
        required: true,
        requiredMesage: "Vui lòng chọn ngày sinh",
        inputTypeDate: true,
    },
    {
        key: 'identityCard',
        label: 'Số CMND/Thẻ căn cước',
        required: true,
        requiredMesage: "Vui lòng nhập số CMND/Thẻ căn cước",
        type: 'number',
        typeMessage: 'CMND/Thẻ căn cước phải là kiểu số'
    },
    {
        key: 'identityCardDay',
        label: 'Ngày cấp',
        required: true,
        requiredMesage: "Vui lòng nhập ngày cấp",
        inputTypeDate: true,
    },
    {
        key: 'identityCardPlace',
        label: 'Nơi cấp',
        required: true,
        requiredMesage: "Vui lòng nhập nơi cấp",
    },
    {
        key: 'nation',
        label: 'Quốc gia',
        required: true,
        requiredMesage: "Vui lòng nhập tên quốc gia",
    },
    /*  {
        key: 'position',
        label: 'Chức danh',
      },
      {
        key: 'unit',
        label: 'Đơn vị',
      },*/
];

//Trường hợp update sẽ ẩn username, password và email đi
export const LIST_FIELD_INPUT_GENERAL_FORM_UPDATE = [
    {
        key: 'fullname',
        label: 'Họ và tên',
        required: true,
        requiredMesage: "Vui lòng nhập họ tên",
    },
    {
        key: 'phoneNumber',
        label: 'Số điện thoại',
        required: true,
        requiredMesage: "Vui lòng nhập số điện thoại",
    },
    {
        key: 'address',
        label: 'Địa chỉ',
        required: true,
        requiredMesage: "Vui lòng nhập địa chỉ",
    },
    {
        key: 'sex',
        label: 'Giới tính',
        required: true,
        requiredMesage: "Vui lòng chọn giới tính",
        inputTypeGender: true,
    },
    {
        key: 'birthday',
        label: 'Ngày sinh',
        required: true,
        requiredMesage: "Vui lòng chọn ngày sinh",
        inputTypeDate: true,
    },
    {
        key: 'identityCard',
        label: 'Số CMND/Thẻ căn cước',
        required: true,
        requiredMesage: "Vui lòng nhập số CMND/Thẻ căn cước",
        type: 'number',
        typeMessage: 'CMND/Thẻ căn cước phải là kiểu số'
    },
    {
        key: 'identityCardDay',
        label: 'Ngày cấp',
        required: true,
        requiredMesage: "Vui lòng nhập ngày cấp",
        inputTypeDate: true,
    },
    {
        key: 'identityCardPlace',
        label: 'Nơi cấp',
        required: true,
        requiredMesage: "Vui lòng nhập nơi cấp",
    },
    {
        key: 'nation',
        label: 'Quốc gia',
        required: true,
        requiredMesage: "Vui lòng nhập tên quốc gia",
    }
];

export const LIST_APPLICATION = [
    {
        key: 'job',
        name: 'Quản lý công việc',
        subName: 'E-Office',
    },
    {
        key: 'job2',
        name: 'Quản lý công việc',
        subName: 'E-Office',
    },
    {
        key: 'job3',
        name: 'Quản lý công việc',
        subName: 'E-Office',
    },
    {
        key: 'job4',
        name: 'Quản lý công việc',
        subName: 'E-Office',
    },
    {
        key: 'job5',
        name: 'Quản lý công việc',
        subName: 'E-Office',
    },
    {
        key: 'job6',
        name: 'Quản lý công việc',
        subName: 'E-Office',
    },
    {
        key: 'job7',
        name: 'Quản lý công việc',
        subName: 'E-Office',
    },
];
