// import axios from 'store/axios';
import _axios from 'utils/axios'
import * as UnitService from 'apis/service/UnitService'
// export const createUser = data => {
//   return axios.post('/account/user/createUser', data).then(res => {
//     console.log('getAccount', res);
//     return res;
//   });
// };

export const getUnitsNotTree = () => {
  return UnitService.getUnitsNotTree()
    .then(res => {
      return res
    })
}

// export const getAccount = query => {
//   console.log('cuong7');
//   return axios
//     .get('/account/getAccount/user-1', { params: formatQuery(query) })
//     .then(res => {
//       console.log('getAccount', res);
//       return res;
//     });
// };
