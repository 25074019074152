import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getItem } from 'utils/localStorage';
import { getCookieItem } from '../../utils/cookie';
// import { TYPE_LOCAL_STORAGE } from 'utils/constants';
// import { getItem, removeItem } from 'utils/localStorage';

export const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!getItem('SMART_SALES_TOKEN') || !getCookieItem('token')) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          );
        }
        // if (!auth()) {
        // return <Component {...props} />;
        // } else {
        //   const url_redirect = getItem(TYPE_LOCAL_STORAGE.URL_REDIRECT);
        //   removeItem(TYPE_LOCAL_STORAGE.URL_REDIRECT);
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: url_redirect || '/',
        //       }}
        //     />
        //   );
        // }
      }}
    />
  );
};
