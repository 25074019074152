import { createSelector } from 'redux-starter-kit';

const appSelector = createSelector(['app'], app => app);

export const loadingSelector = createSelector(
  [appSelector],
  app => app.loading,
);

export const tokenSelector = createSelector([appSelector], app => app.token);
