import { createSlice } from 'redux-starter-kit';

const initialState = {
  unitsNotTree: [],
};

export default createSlice({
  slice: 'app',
  initialState,
  reducers: {
    setUnitsNotTree: (state, action) => ({
      ...state,
      unitsNotTree: action.payload,
    }),

  },
});
