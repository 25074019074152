import { createContext, useContext } from 'react';

const authenticationSSOContext = createContext({ authenticationSSO: false, setAuthenticationSSO: b => {} });

export const AuthenticationSSOContextProvider = authenticationSSOContext.Provider;

export const useAuthenticationSSOContext = () => {
    const state = useContext(authenticationSSOContext);

    return state;
};
