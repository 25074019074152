import { memo, useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute, PublicRoute } from 'components/RouteGuard';
import { Dashboard } from 'containers/Dashboard/Loadable';
import { Admin } from 'containers/Admin/Loadable';
import { Login } from 'containers/Login/Loadable';
import { Logout } from 'containers/Logout/Loadable';
import { ForgotPassword } from 'containers/ForgotPassword/Loadable';
import { ResetPassword } from 'containers/ResetPassword/Loadable';
import './style.css';
import { NotFound } from '../NotFound-404/Loadable';
import { MyInfo } from '../MyInfo/Loadable';
import { AuthenticationSSOContextProvider } from './context';
import LoginSSOCallback from 'containers/LoginSSOCallback';

const App = memo(() => {
  const [authenticationSSO,setAuthenticationSSO] = useState(false)
  useEffect(() => {
  }, []);

  return (
    <AuthenticationSSOContextProvider value={{ authenticationSSO, setAuthenticationSSO }}>
    <div className="container">
    {/*<div className="container">*/}
      <Switch>
        <ProtectedRoute exact path={'/'} component={Dashboard}/>
        <ProtectedRoute path={'/admin'} component={Admin}/>
        <ProtectedRoute path={'/info'} component={MyInfo}/>
        <PublicRoute path={'/login'} component={Login}/>
        <ProtectedRoute path={'/logout'} component={Logout}/>
        <PublicRoute path={'/forgot-password'} component={ForgotPassword}/>
        <PublicRoute path={'/reset-password'} component={ResetPassword}/>
        <PublicRoute path={'/login-sso-callback'} component={LoginSSOCallback}/>
        <ProtectedRoute path={'/404'} component={NotFound}/>
      </Switch>
    </div>
    </AuthenticationSSOContextProvider>
  );
});

App.propTypes = {};
App.defaultProps = {};

export default App;
