import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getCookieItem = key => cookies.get(key);

const setCookieItem = (key, value) => {
  if (key && value) cookies.set(key, value, { path: '/' });
};

const removeCookieItem = key => cookies.remove(key,{ path: '/' });

export { getCookieItem, setCookieItem, removeCookieItem };
