import _ from 'lodash';

export default ({
  actions = {},
  process = () => {},
  ...options
}) => payload => async (dispatch, getState, objDeps) => {
  const execute = async () => {
    console.log('createOperation');
    const { startAction, successAction, failedAction } = actions;
    if (_.isFunction(startAction)) {
      dispatch(startAction(payload));
    }
    try {
      const result = await process({ payload, dispatch, getState });
      if (_.isFunction(successAction)) {
        dispatch(successAction({ result, params: payload }));
      }

      let onSuccess;
      if (options && options.onSuccess) {
        ({ onSuccess } = options);
      }
      if (_.isFunction(onSuccess)) {
        onSuccess({ dispatch, getState, params: payload, result, ...objDeps });
      }
      if (options?.successMessage) {
        console.log(options.successMessage);
      }

      return result;
    } catch (error) {
      let onError;
      // let onOffline;
      if (error.message === 'Network Error') {
        return null;
      }

      if (options && options.onError) {
        if (error.request.status === 401) {
          options.errorMessage = 'Hết phiên làm việc, vui lòng đăng nhập lại!';
        }
        ({ onError } = options);
      }
      if (_.isFunction(onError)) {
        onError();
      }

      // show error message on error
      // let customMessage;
      // if (error.response?.data) {
      //   const {
      //     response: { data },
      //   } = error;
      //   if (data.message === 'BusinessErrorException') {
      //     const { errors } = data;
      //
      //     if (_.isFunction(options?.getCustomMessage)) {
      //       customMessage = options.getCustomMessage({ errors, payload, dispatch, getState });
      //     } else {
      //       customMessage = getErrorMessage(errors[0].errorCode);
      //     }
      //   }
      // } else if (!selectors.isOnlineSelector(getState())) {
      //   customMessage = 'Mất kết nối mạng';
      // }
      //
      // Alert.alert('Thông báo', customMessage || options?.errorMessage || 'Lỗi không xác định', [
      //   { text: 'Đóng', style: 'destructive' },
      // ]);

      if (_.isFunction(failedAction)) {
        dispatch(failedAction({ error }));
      }

      return null;
    }
  };

  if (options.requireConfirm) {
    // return Alert.alert(options.confirmTitle || 'Xác nhận', options.confirmMessage || '', [
    //   { text: 'Đồng ý', onPress: () => execute(), style: 'default' },
    //   { text: 'Huỷ', onPress: () => {}, style: 'cancel' },
    // ]);
  }

  return execute();
};
