import createOperation from '../createOperation';
import * as _unitService from './service';

export const getUnitsNotTree = createOperation({
  process: async ({ dispatch, payload: { } }) => {
    try {
      await _unitService.getUnitsNotTree()
    } catch (e) {
      // console.log("===>createUnit error: ", e)
    }
  }
})
