import { memo, useEffect } from 'react';
import { commonVariable } from 'utils/common';


const LoginSSOCallback = memo(() => {
  const url = window.location.href;
  console.log({url});
  const urlRedirectSSO = `${process.env.REACT_APP_AUTH_URL}?client_id=${commonVariable.client_id}&scope=openid&response_type=code&redirect_uri=${url.replace('/login-sso-callback', '/login')}&state=${''}`

  useEffect(() => {
    window.location.href = urlRedirectSSO;
  }, []);
  return (
    <div>
    </div>
  );
});



export default LoginSSOCallback;
