import axios from 'axios';
import Qs from 'qs';
import {openNotificationWithIcon} from './helper';
import {getItem} from './localStorage';
import * as Util from "./utils";
import * as ConstantEnv from "./constants_env";
import { history } from './history';
import { getSubUrl } from './utils';


const onSuccessInterceptorRequest = async config => {
    const token = getItem('SMART_SALES_TOKEN');
    if (token) config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['LdapType'] = 'normal';

    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Accept'] = 'application/json';
    // axios.defaults.headers.post['Authorization'] = getItem('SMART_SALES_TOKEN');
    axios.defaults.headers.post['LdapType'] = 'normal';
    config.paramsSerializer = params =>
        Qs.stringify(params, {
            arrayFormat: 'brackets',
        });
    return config;
};
const onErrorInterceptorRequest = error => Promise.reject(error);


let isLockAccount = false;

const onErrorInterceptorResponse = error => {
    console.log("onErrorInterceptorResponse error.response", error.response)
    if (error.response && error.response.status) {
        console.log("onErrorInterceptorResponse error.response.status", error.response.status)
        if (error.response.status !== 200) {
            if(error.response.status === 401){
                if(!isLockAccount){
                    isLockAccount = true;
                    history.push("/logout");
                    Util.showNotification('error', "error",'authenticate.userInactive')
                }
            }
            else if (error.response)
                Util.showNotificationError(error.response)
            // if (error.response.status === 401) {
            //     openNotificationWithIcon(
            //         'error',
            //         'Error',
            //         error.response.statusText,
            //         5,
            //     );
            //     // store.dispatch(actions.logout());
            // }
        }
    }
    return Promise.reject(error);
};
const onSuccessInterceptorResponse = response => {
    return response.data;
};

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Authorization'] = getItem('SMART_SALES_TOKEN');
axios.defaults.headers.post['LdapType'] = 'normal';

const _axios = axios.create({
    baseURL: process.env.REACT_APP_API_URL || getSubUrl(),
    timeout: 120 * 1000,
    // withCredentials: true, // Check cross-site Access-Control
});

_axios.interceptors.request.use(
    onSuccessInterceptorRequest,
    onErrorInterceptorRequest,
);

_axios.interceptors.response.use(
    onSuccessInterceptorResponse,
    onErrorInterceptorResponse
    // onErrorInterceptorResponse,
);

/**
 *
 * @NOTE primary methods axios
 *
 */
class AxiosXHRConstructor {
    axiosInstance;

    constructor(axiosInstance) {
        this.axiosInstance = axiosInstance;
        this.$get = this.$get.bind(this);
        this.$post = this.$post.bind(this);
        this.$put = this.$put.bind(this);
        this.$delete = this.$delete.bind(this);
    }

    $get(url, params, config) {
        return this.axiosInstance.get(url, {
            ...{params},
            ...config,
        });
    }

    $post(url, data, config) {
        return this.axiosInstance.post(url, data, config);
    }

    $postDownload(url, data, config) {
        return this.axiosInstance.post(url, data, { responseType: 'arraybuffer' });
    }

    $put(url, data, config) {
        return this.axiosInstance.put(url, data, config);
    }

    $delete(url, data) {
        // return this.axiosInstance.delete(url, {
        //   data,
        // });

        /**
         * @hotfix {https://github.com/axios/axios/issues/3220}
         */
        return this.axiosInstance.request({
            method: 'delete',
            url,
            data,
        });
    }
}

export const BaseXHR = new AxiosXHRConstructor(_axios);

export default _axios;
