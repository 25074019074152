import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { configureStore } from 'redux-starter-kit';
import { reducer as appReducer } from './app';
import { reducer as accountReducer } from './account';
import {reducer as unitReducer} from './unit';

import logger from './logger';

const reducer = combineReducers({
  app: appReducer,
  account: accountReducer,
  unit: unitReducer,
});

// console.log('node_env: ' + process.env.NODE_ENV);

const middleware =
  process.env.NODE_ENV !== 'production' ? [logger, thunk] : [thunk];

const store = configureStore({
  reducer,
  middleware,
});

export default store;
