import createOperation from '../createOperation';
import * as authService from './service';

export const getAccount = createOperation({
  async process({ dispatch }) {
    const account = await authService.getAccount();
    if (account) {
      //set header

      return account;
    }
    return null;
  },
});
