import {KEY_LOGIN_PASSWORD, KEY_LOGIN_USER} from "../containers/Admin-Members/constants";

const getItem = key => localStorage.getItem(key);

const setItem = (key, value) => {
    if (key && value) localStorage.setItem(key, value);
};

const removeItem = key => localStorage.removeItem(key);

const clearAllLS = () => {
    //Clear all nhưng phải để lại login username và password
    const loggedInUser = getItem(KEY_LOGIN_USER);
    const loggedInPassword = getItem(KEY_LOGIN_PASSWORD);

    localStorage.clear()

    if (loggedInUser && loggedInPassword) {
        setItem(KEY_LOGIN_USER, loggedInUser)
        // setItem(KEY_LOGIN_PASSWORD, loggedInPassword)
    }
};

export {getItem, setItem, removeItem, clearAllLS};
