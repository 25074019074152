import _ from 'lodash';
import { notification } from 'antd';
import vi from '../locales/vi.json';
import en from '../locales/en.json';
import { getItem } from './localStorage';
import { BASE_URL } from './constants_env';
import * as moment from 'moment';

export function showNotificationError(errorCode) {
  const language = getItem('language') === 'en' ? en : vi;
  //'success', 'info', 'warning', 'error'
  notification['error']({
    message: language.error,
    description: handleError(errorCode, language),
    top:80
  });
}

export function showNotification(type, title, description,top) {
  const language = getItem('language') == 'vi' ? vi : en;
  //'success', 'info', 'warning', 'error'
  notification[type]({
    message: language[title],
    description: language[description],
    top:top ? top : 80
  });
}

export function showNotificationBottom(type, title, description) {
  showNotificationBottom222(type, title, description)
};

const showNotificationBottom222 = (placement, title, description) => {
  const language = getItem('language') == 'vi' ? vi : en;
  notification.warn({
    message: language[title],
    description: language[description],
    placement,
    top:80
  });
};

export function showNotificationDirect(type, title, description) {
  //'success', 'info', 'warning', 'error'
  notification[type]({
    message: title,
    description: description,
    top:24
  });
}

const handleError = (error, language) => {
  const errorData = error.data;
  if (errorData.errors) {
    const errorCode = errorData.errors.errorCode;
    let params = errorData.errors.params;
    if(params[2]){
      params =JSON.parse(params[2])?.message
    }
    return language[errorCode] + (params != null ? ': ' + params : '');
  } else {
    return language.errorDefault;
  }
};


export const formatQuery = query => {
  const formatted = {};

  _.forEach(query, (v, k) => {
    formatted[k] = v;
  });
  return formatted;
};

export function isValidPhonenumber(inputtxt) {
  var phoneReg = /^[0-9]{10}$|^[0-9]{11}$|^[+][0-9]{11}$|^$/;
  if (inputtxt.match(phoneReg)) {
    return true;
  } else {
    return false;
  }
}

export function isValidEmail(inputtxt) {
  var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (inputtxt.match(reg)) {
    return true;
  } else {
    return false;
  }
}

export function isValidNumber(n) {
  return Number(n) === n && n % 1 === 0;
}

export function stringHasSpaces(str) {
  if (str.indexOf(' ') !== -1) {
    return true;
  } else {
    return false;
  }
}

// export const PATTERN_EMAIL = '^$|[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
export const PATTERN_EMAIL = '^\\w+([._%+-]?\\w+)\\w+([._%+-]?\\w+)\\w+([._%+-]?\\w+)\\w+([._%+-]?\\w+)\\w+([._%+-]?\\w+)@\\w+([.-]?\\w+)(.\\w{2,3})+$';
// export const PATTERN_PHONE_NUMBER = "[0-9]{10}|[0-9]{11}";
export const PATTERN_PHONE_NUMBER = '[ ]*[+]{0,1}([0-9]{10}|[0-9]{11}|[0-9]{11})[ ]*'; // cho phep ký tự space
// export const PATTERN_IDENTITY = "[0-9]+";
export const PATTERN_IDENTITY = '[ ]*|[ ]*[0-9]+[ ]*'; //cho phep ký tự space

export const PATTERN_PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

export const PATTERN_USERNAME = '[a-zA-Z0-9].*@[a-zA-Z0-9].*';
export const PATTERN_USERNAME_NOT_CODE = '[a-zA-Z0-9].*';

export function getSubUrl() {
  const fullDomain = window.location.host;
  const end = fullDomain.indexOf('smart-sales.vn');
  const subDomain = (end - 1) > 0 ? fullDomain.substring(0, end - 1) : '';
  if (subDomain && subDomain.length > 0) {
    return BASE_URL.replace('://', '://' + subDomain + '.');
  }
  return BASE_URL;
}

export const formatDateStrToLong = (dateStr, format) => {
  if (dateStr) {
    return moment(dateStr, format || 'DD-MM-YYYY')
      .toDate()
      .getTime();
  }
  return;
};
