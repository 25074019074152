import { Redirect, Route } from 'react-router-dom';
import { clearAllLS, getItem } from 'utils/localStorage';
import { getCookieItem, removeCookieItem } from '../../utils/cookie';
// import { TYPE_LOCAL_STORAGE } from 'utils/constants';
// import { history } from 'utils/history';
// import { setItem } from 'utils/localStorage';
// import { LIST_PUBLIC_ROUTE } from './constants';
// import jwt from 'jwt-decode';
import * as Util from '../../utils/utils';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = getItem('SMART_SALES_TOKEN');
  if (token) {
    const tokenDecode = JSON.parse(atob(token.split('.')[1]));
    const now = Date.now();
    if (now > (tokenDecode.exp* 1000)) {
      //if (Date.now() > ((tokenDecode.exp - 1) * 1000)) {
      clearAllLS();
      removeCookieItem('token');
      Util.showNotificationDirect('warning', 'Thông báo', 'Phiên đăng nhập của bạn đã hết hạn. Vui lòng đăng nhập lại.');
    }
  }

  // const tokenDecode = jwt(token);
  // const expired = (Date.now() > ((tokenDecode.exp - 1) * 1000));
  // if (expired) {
  //   clearAllLS();
  //   removeCookieItem('token');
  //   Util.showNotificationDirect('warning', 'Thông báo', 'Phiên đăng nhập của bạn đã hết hạn. Vui lòng đăng nhập lại.');
  // }

  return (
    <Route
      {...rest}
      render={props => {
        if (!getItem('SMART_SALES_TOKEN') || !getCookieItem('token')) {
          return <Redirect to="/login"/>;
        }
          // else if (rest.path === '/admin' && JSON.parse(getItem('userInfo')) && (JSON.parse(getItem('userInfo')).isAdmin) !== 1) {
          //   return <Redirect to="/404"/>;
        // }
        else {
          return <Component {...props} />;
        }
      }
      }
    />
  );
};
