import { BaseXHR } from '../../utils/axios';

export function getUnits(params) {
  return BaseXHR.$get('/department', params);
}

export function getUnitsNotTree() {
  return BaseXHR.$get('/department/allnottree');
}

export function createUnit(params) {
  return BaseXHR.$post('/department', params);
}

export function updateUnit(id, params) {
  return BaseXHR.$put('/department/' + id, params);
}

export function getUnitById(id) {
  return BaseXHR.$get('/department/' + id);
}

export function activeUnit(id) {
  return BaseXHR.$post('/department/active/' + id);
}

export function inactiveUnit(id) {
  return BaseXHR.$post('/department/inactive/' + id);
}

export function deleteUnit(id) {
  return BaseXHR.$delete('/department/' + id);
}

export function searchUnit(params) {
  return BaseXHR.$get('/department', params);
}
